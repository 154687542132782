<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="about-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">ABOUT US</span>
                    <h2>Most Probably You Are Getting Best App Ever</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <div class="features-text">
                        <h6>Our App</h6>
                        <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                    </div>
                    <div class="features-text">
                        <h6>Our Mission</h6>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy of your business purpose text.</p>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/contact" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-1" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/app/app-img8.png" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>

<app-progress-style-one></app-progress-style-one>

<div class="app-download-area">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<div class="funfacts-area ptb-100">
    <app-funfacts-style-two></app-funfacts-style-two>
</div>

<app-feedback-style-four></app-feedback-style-four>

<div class="pt-100">
    <app-homethree-video></app-homethree-video>
</div>

<app-team-style-two></app-team-style-two>

<div class="partner-area">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-software-integrations></app-software-integrations>

<app-footer-style-two></app-footer-style-two>