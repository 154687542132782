import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-style-five',
  templateUrl: './pricing-style-five.component.html',
  styleUrls: ['./pricing-style-five.component.scss']
})
export class PricingStyleFiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
