<div class="features-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="features-content">
                    <h2>Most Probably You Are Getting Best App Ever</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="ri-download-cloud-2-line"></i>
                            </div>
                            <h3>Free Download App</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your business purpose.</p>
                        </li>
                        <li>
                            <div class="icon bg2">
                                <i class="ri-award-line"></i>
                            </div>
                            <h3>Trusted and Reliable</h3>
                            <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                        </li>
                        <li>
                            <div class="icon bg3">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum.</p>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/app-download" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-1" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="features-image text-center">
                    <img src="assets/img/app/app-img1.png" alt="app-img" data-aos="fade-up">
                    <div class="shape">
                        <img class="shape3" src="assets/img/shape/shape2.png" alt="shape">
                        <img class="shape4" src="assets/img/shape/shape3.png" alt="shape">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-shape1"><img src="assets/img/shape/bg-shape1.png" alt="bg-shape"></div>
</div>