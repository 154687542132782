<div class="new-free-trial-area pb-100">
    <div class="container">
        <div class="new-free-trial-inner-box wrap-with-gradient">
            <div class="row align-items-center">
                <div class="col-lg-9">
                    <div class="new-free-trial-content">
                        <span class="sub-title">FREE TRIAL</span>
                        <h2>Start Your 14 Days Free Trials Today!</h2>
                        <form class="free-trial-form">
                            <input type="text" class="input-newsletter" placeholder="Enter Your Email Address" name="email">
                            <button type="submit" class="default-btn">Get Started</button>
                        </form>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="new-free-trial-image">
                        <img src="assets/img/more-home/free-trial/free-trial.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="new-free-trial-shape">
                <img src="assets/img/more-home/free-trial/shape.png" alt="image">
            </div>
        </div>
    </div>
</div>