<div class="footer-area">
  <div class="container">
    <div class="footer-content">
      <a routerLink="/" class="logo">
        <img src="assets/img/protrack/logo-peb.png" alt="logo">
      </a>
      <ul class="social-links">
        <li>
          <a href="https://www.facebook.com/protracktelemetria" target="_blank"><i
              class="ri-facebook-fill"></i></a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/protracktelemetria/" target="_blank"><i
              class="ri-linkedin-fill"></i></a>
        </li>
        <li>
          <a href="https://api.whatsapp.com/send/?phone=5585988646989&text&type=phone_number&app_absent=0"
            target="_blank"><i class="ri-messenger-fill"></i></a>
        </li>
        <li>
          <a href="https://www.instagram.com/protrack_telemetria/" target="_blank"><i class="ri-instagram-fill"></i></a>
        </li>
        <!-- <li><a href="#" target="_blank"><i class="ri-github-fill"></i></a></li> -->
      </ul>
      <!-- <ul class="navbar-nav">
        <li class="nav-item"><a routerLink="/services" class="nav-link">Services</a></li>
        <li class="nav-item"><a routerLink="/contact" class="nav-link">Support</a></li>
        <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link">Privacy Policy</a></li>
        <li class="nav-item"><a routerLink="/faq" class="nav-link">FAQ's</a></li>
        <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact</a></li>
      </ul> -->
      <p class="copyright">Copyright © {{getCurrentYear()}} <a title="Develop by AdapteTI"
          href="https://www.adapteti.com.br" target="_blank">ProTrack</a>.
      </p>
    </div>
  </div>
</div>