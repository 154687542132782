<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing Plan</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Pricing Plan</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="pricing-area pt-100 pb-75">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">PRICING TABLE</span>
            <h2>No Hidden Charge Applied, Choose Your Plan</h2>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="title">
                        <h3>Basic</h3>
                        <p>Powerful & awesome elements</p>
                    </div>
                    <div class="price">
                        $39 <span>/Month</span>
                    </div>
                    <a routerLink="/" class="default-btn">Purchase Plan</a>
                    <ul class="features-list">
                        <li><i class="ri-check-line"></i> Up to 10 Website</li>
                        <li><i class="ri-check-line"></i> Lifetime Free Support</li>
                        <li><i class="ri-check-line"></i> 10 GB Dedicated Hosting Free</li>
                        <li><i class="ri-check-line"></i> 24/7 Support</li>
                        <li><i class="ri-close-line"></i> SEO Optimized</li>
                        <li><i class="ri-close-line"></i> Live Support</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box active">
                    <div class="title">
                        <h3>Team</h3>
                        <p>Powerful & awesome elements</p>
                    </div>
                    <span class="popular">Most Popular</span>
                    <div class="price">
                        $49 <span>/Month</span>
                    </div>
                    <a routerLink="/" class="default-btn">Purchase Plan</a>
                    <ul class="features-list">
                        <li><i class="ri-check-line"></i> Up to 200 Website</li>
                        <li><i class="ri-check-line"></i> Lifetime Free Support</li>
                        <li><i class="ri-check-line"></i> 25 GB Dedicated Hosting Free</li>
                        <li><i class="ri-check-line"></i> 24/7 Support</li>
                        <li><i class="ri-check-line"></i> SEO Optimized</li>
                        <li><i class="ri-close-line"></i> Live Support</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="title">
                        <h3>Business</h3>
                        <p>Powerful & awesome elements</p>
                    </div>
                    <div class="price">
                        $59 <span>/Month</span>
                    </div>
                    <a routerLink="/" class="default-btn">Purchase Plan</a>
                    <ul class="features-list">
                        <li><i class="ri-check-line"></i> Up to 500 Website</li>
                        <li><i class="ri-check-line"></i> Lifetime Free Support</li>
                        <li><i class="ri-check-line"></i> 50 GB Dedicated Hosting Free</li>
                        <li><i class="ri-check-line"></i> 24/7 Support</li>
                        <li><i class="ri-check-line"></i> SEO Optimized</li>
                        <li><i class="ri-check-line"></i> Live Support</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="shape"></div>
    <div class="shape13"><img src="assets/img/shape/shape15.png" alt="shape"></div>
</div>

<div class="pricing-area bg-gradient-color pt-100 pb-75">
    <app-pricing-style-one></app-pricing-style-one>
    <div class="shape7"><img src="assets/img/shape/shape6.png" alt="shape"></div>
    <div class="shape8"><img src="assets/img/shape/shape7.png" alt="shape"></div>
</div>

<div class="pricing-area pt-100 pb-75">
    <app-pricing-style-four></app-pricing-style-four>
</div>

<app-pricing-style-three></app-pricing-style-three>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-pricing-style-five></app-pricing-style-five>

<div class="app-download-area ptb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div>

<app-footer-style-one></app-footer-style-one>