import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-style-four',
  templateUrl: './footer-style-four.component.html',
  styleUrls: ['./footer-style-four.component.scss']
})
export class FooterStyleFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
