<div class="features-area pt-100 pb-75">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="features-inner-content">
                    <span class="sub-title">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want chance its just began.</p>
                    <div class="btn-box">
                        <a routerLink="/pricing" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-2" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 features-inner-list">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-sm-6">
                        <div class="features-inner-card">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                                <h3>High Resolution</h3>
                            </div>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="features-inner-card with-box-shadow">
                            <div class="icon">
                                <i class="ri-stack-line"></i>
                                <h3>Retina Ready Screen</h3>
                            </div>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="features-inner-card with-box-shadow">
                            <div class="icon">
                                <i class="ri-cloud-line"></i>
                                <h3>Cloud Storage</h3>
                            </div>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="features-inner-card">
                            <div class="icon">
                                <i class="ri-leaf-line"></i>
                                <h3>Easy Editable Data</h3>
                            </div>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="features-area pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="features-box-card">
                    <div class="icon">
                        <i class="ri-smartphone-line"></i>
                    </div>
                    <h3>User Friendly</h3>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="features-box-card">
                    <div class="icon bg2">
                        <i class="ri-award-line"></i>
                    </div>
                    <h3>Award Winning App</h3>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="features-box-card">
                    <div class="icon">
                        <i class="ri-fingerprint-line"></i>
                    </div>
                    <h3>Privacy Protected</h3>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="features-box-card">
                    <div class="icon bg2">
                        <i class="ri-vip-diamond-line"></i>
                    </div>
                    <h3>Lifetime Update</h3>
                </div>
            </div>
        </div>
    </div>
</div>