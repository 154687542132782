<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Cart</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Cart</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/products-details"><img src="assets/img/products/products-img1.jpg" alt="item"></a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/products-details">Laptop Blue Background</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$250.00</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <input type="number" value="1">
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$250.00</span>
                                <a routerLink="/cart" class="remove"><i class="ri-delete-bin-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/products-details"><img src="assets/img/products/products-img2.jpg" alt="item"></a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/products-details">Pink Smartwatch</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$200.00</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <input type="number" value="1">
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$200.00</span>
                                <a routerLink="/cart" class="remove"><i class="ri-delete-bin-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/products-details"><img src="assets/img/products/products-img3.jpg" alt="item"></a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/products-details">Black Headphone</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$200.00</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <input type="number" value="1">
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$200.00</span>
                                <a routerLink="/cart" class="remove"><i class="ri-delete-bin-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/products-details"><img src="assets/img/products/products-img4.jpg" alt="item"></a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/products-details">Fashion Film Camera</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$150.00</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <input type="number" value="1">
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$150.00</span>
                                <a routerLink="/cart" class="remove"><i class="ri-delete-bin-line"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-12 col-md-7">
                        <div class="shopping-coupon-code">
                            <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                            <button type="submit">Apply Coupon</button>
                        </div>
                    </div>
                    <div class="col-lg-5 col-sm-12 col-md-5 text-end">
                        <a routerLink="/cart" class="default-btn">Update Cart</a>
                    </div>
                </div>
            </div>
            <div class="cart-totals">
                <ul>
                    <li>Subtotal <span>$800.00</span></li>
                    <li>Shipping <span>$30.00</span></li>
                    <li>Total <span>$830.00</span></li>
                </ul>
                <a routerLink="/checkout" class="default-btn">Proceed to Checkout</a>
            </div>
        </form>
    </div>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<app-footer-style-one></app-footer-style-one>