<div class="container">
    <div class="section-title">
        <span class="sub-title">CLIENT REVIEWS</span>
        <h2>What Our Customer Say About Us</h2>
    </div>
    <div class="feedback-swiper-slides">
        <owl-carousel-o [options]="swiperFeedbackSlides">
            <ng-template carouselSlide>
                <div class="single-feedback-item">
                    <div class="client-info">
                        <img src="assets/img/user/user1.jpg" alt="user">
                        <div class="title">
                            <h3>Deanna Hodges</h3>
                            <span>Spotify Developer</span>
                        </div>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type. Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                    <div class="rating">
                        <h5>Theme Customization</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-feedback-item">
                    <div class="client-info">
                        <img src="assets/img/user/user2.jpg" alt="user">
                        <div class="title">
                            <h3>Londynn Vargas</h3>
                            <span>PHP Developer</span>
                        </div>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type. Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                    <div class="rating">
                        <h5>Theme Customization</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-feedback-item">
                    <div class="client-info">
                        <img src="assets/img/user/user3.jpg" alt="user">
                        <div class="title">
                            <h3>James Andy</h3>
                            <span>Moodle Developer</span>
                        </div>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type. Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                    <div class="rating">
                        <h5>Theme Customization</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-line"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>