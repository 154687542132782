<div class="features-area pb-100">
  <div class="container">
    <div class="features-inner">
      <div class="row justify-content-center">
        <div class="col-xl-3 col-lg-3 col-sm-6 col-md-3 col-6">
          <div class="features-box">
            <div style=" background: linear-gradient(
              45.24deg,
              #0d6efd 5.99%,
              #0d6efd78 91.87%
            ) ;" class="icon bg1">
              <i class="ri-radar-line"></i>
            </div>
            <h3>Telemetria e Rastreamento Completo</h3>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6 col-md-3 col-6">
          <div class="features-box">
            <div style="background: linear-gradient(44.44deg, #198754 7.79%, #05bb678a 94.18%);" class="icon bg2">
              <i class="ri-money-dollar-circle-line"></i>
            </div>
            <h3>Faturamento </h3>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6 col-md-3 col-6">
          <div class="features-box">
            <div class="icon bg3">
              <i class="ri-line-chart-line"></i>
            </div>
            <h3>Redução de inadimplência</h3>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6 col-md-3 col-6">
          <div class="features-box">
            <div style="background:linear-gradient(44.44deg, #ff4b3ff0 7.79%, #ffac30de 94.18%)" class="icon bg4">
              <i class="ri-organization-chart"></i>
            </div>
            <h3>Organização de processos </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="features-area pb-75">
  <app-features-style-four></app-features-style-four>
  <app-progress-style-one></app-progress-style-one>
</div>