import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-software-integrations',
    templateUrl: './software-integrations.component.html',
    styleUrls: ['./software-integrations.component.scss']
})
export class SoftwareIntegrationsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}