<div class="container">
    <div class="section-title">
        <span class="sub-title">APP SCREENS</span>
        <h2>Beautifully Crafted All App Screenshots</h2>
    </div>
    <div class="screenshots-swiper-slides">
        <owl-carousel-o [options]="screenshotsSwiperSlides">
            <ng-template carouselSlide>
                <div class="swiper-slide">
                    <img src="assets/img/screenshots/screen1.png" alt="screenshots">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="swiper-slide">
                    <img src="assets/img/screenshots/screen1.png" alt="screenshots">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="swiper-slide">
                    <img src="assets/img/screenshots/screen1.png" alt="screenshots">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="swiper-slide">
                    <img src="assets/img/screenshots/screen1.png" alt="screenshots">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>