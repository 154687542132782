<!-- <app-navbar-style-one></app-navbar-style-one> -->

<!-- <div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>About Us</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>About Us</li>
      </ul>
    </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div> -->

<div class="about-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <span class="sub-title">Quem somos?</span>
          <h2>Conheça a ProTrack</h2>
          <p>Nós somos um grupo de profissionais apaixonados por tecnologia e
            comprometidos em impulsionar o sucesso de nossos clientes. Com uma equipe
            talentosa, temos a experiência necessária para criar soluções personalizadas que
            atendam às demandas específicas de empresas de rastreamento veicular</p>
          <div class="features-text">
            <h6>Missão</h6>
            <p>Nossa missão é alcançar a transparência em nossa tecnologia, garantindo a sua
              tranquilidade e proporcionando segurança tanto para o seu veículo quanto para sua
              vida, por meio da transparência em nossa tecnologia.</p>
          </div>
          <div class="features-text">
            <h6>Visão</h6>
            <p>Almejamos reconhecimento como uma das principais empresas de soluções de
              rastreamento no Nordeste, destacando-nos como sinônimo de garantia e qualidade.
              Nosso objetivo é estabelecer uma reputação sólida e confiável no fornecimento de
              serviços que atendam com excelência às necessidades de nossos clientes.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="assets/img/app/app-img7.png" data-aos="fade-up" alt="about">
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="funfacts-area pb-75">
  <app-funfacts-style-one></app-funfacts-style-one>
</div> -->

<!-- <app-progress-style-two></app-progress-style-two>

<app-user-stats></app-user-stats>

<app-hometwo-software-integrations></app-hometwo-software-integrations>

<app-team-style-one></app-team-style-one>

<div class="feedback-area pb-100">
  <app-feedback-style-one></app-feedback-style-one>
</div>

<div class="partner-area bg-f9f9f9 ptb-100">
  <div class="container">
    <div class="partner-title">
      Trusted by world famous companies:
    </div>
    <app-partner></app-partner>
  </div>
</div> -->

<!-- <app-footer-style-one></app-footer-style-one> -->