import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homenine-banner',
  templateUrl: './homenine-banner.component.html',
  styleUrls: ['./homenine-banner.component.scss']
})
export class HomenineBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
