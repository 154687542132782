import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homenine-app-progress',
  templateUrl: './homenine-app-progress.component.html',
  styleUrls: ['./homenine-app-progress.component.scss']
})
export class HomenineAppProgressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
