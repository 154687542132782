<div class="container">
  <div class="app-download-inner">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="app-download-content">
          <span class="sub-title">DOWNLOAD APP</span>
          <h2>Baixe o aplicativo ProTrack gratuitamente </h2>
          <p>Instant free download from store Cloud based storage for your data backup just log in with your mail
            account from play store and using whatever you want for your business purpose orem ipsum dummy text.</p>
          <div class="btn-box">
            <a href="#" class="playstore-btn" target="_blank">
              <img src="assets/img/play-store.png" alt="image">
              Get It On
              <span>Google Play</span>
            </a>
            <a href="#" class="applestore-btn" target="_blank">
              <img src="assets/img/apple-store.png" alt="image">
              Download on the
              <span>Apple Store</span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="app-download-image" data-aos="fade-up">
          <img src="assets/img/app/app-img3.png" alt="app-img">
        </div>
      </div>
    </div>
    <div class="shape5"><img src="assets/img/shape/shape4.png" alt="shape4"></div>
    <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
</div>