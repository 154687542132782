<div class="app-pricing-area pt-100 pb-75">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="app-pricing-section-title">
                    <span class="sub-title">PRICING TABLE</span>
                    <h2>No Hidden Charge Applied, Choose Your Plan</h2>
                    <a routerLink="/pricing">See All Pricing Plan</a>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="single-app-pricing-box with-border-radius">
                            <div class="title">
                                <h3>Small Team</h3>
                                <p>Powerful & awesome elements</p>
                            </div>
                            <span class="popular">Most Popular</span>
                            <div class="price">
                                $59 <span>/Month</span>
                            </div>
                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Purchase Plan</a>
                            </div>
                            <ul class="features-list">
                                <li><i class="ri-check-line"></i> Up to 10 Website</li>
                                <li><i class="ri-check-line"></i> Lifetime free Support</li>
                                <li><i class="ri-check-line"></i> 10 GB Dedicated Hosting free</li>
                                <li><i class="ri-check-line"></i> 24/7 Hours Support</li>
                                <li><i class="ri-check-line"></i> SEO Optimized</li>
                                <li><i class="ri-check-line"></i> Live Support</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="single-app-pricing-box with-border-radius">
                            <div class="title">
                                <h3>Business</h3>
                                <p>Powerful & awesome elements</p>
                            </div>
                            <div class="price">
                                $69 <span>/Month</span>
                            </div>
                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Purchase Plan</a>
                            </div>
                            <ul class="features-list">
                                <li><i class="ri-check-line"></i> Up to 10 Website</li>
                                <li><i class="ri-check-line"></i> Lifetime free Support</li>
                                <li><i class="ri-check-line"></i> 10 GB Dedicated Hosting free</li>
                                <li><i class="ri-check-line"></i> 24/7 Hours Support</li>
                                <li><i class="ri-check-line"></i> SEO Optimized</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>