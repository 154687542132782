import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeeight-banner',
  templateUrl: './homeeight-banner.component.html',
  styleUrls: ['./homeeight-banner.component.scss']
})
export class HomeeightBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
