<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Features</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Features</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<app-homeone-features></app-homeone-features>

<div class="features-area ptb-100 bg-F7F7FF">
    <app-features-style-one></app-features-style-one>
</div>

<div class="pt-100">
    <app-hometwo-features></app-hometwo-features>
</div>

<app-hometwo-key-features></app-hometwo-key-features>

<div class="features-area pt-100 bg-f9f9f9 pb-75">
    <app-features-style-two></app-features-style-two>
</div>

<div class="features-area ptb-100">
    <app-features-style-three></app-features-style-three>
</div>

<div class="features-area pt-100 bg-f9f9f9 pb-75">
    <app-features-style-four></app-features-style-four>
</div>

<div class="features-area pt-100 pb-75">
    <app-features-style-five></app-features-style-five>
</div>

<app-footer-style-two></app-footer-style-two>