<div class="container">
  <div class="section-title">
    <span class="sub-title">KEY FEATURES</span>
    <h2>Most Probably Included Best Features Ever</h2>
  </div>
  <div class="row justify-content-center">
    <div class="col-xl-2 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon">
          <i class="ri-eye-line"></i>
        </div>
        <h3>High Resolution</h3>
        <p>Just log in with your mail account from play store and using whatever you want for your able business
          purpose.</p>
      </div>
    </div>
    <div class="col-xl-2 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg2">
          <i class="ri-stack-line"></i>
        </div>
        <h3>Retina Ready Screen</h3>
        <p>Just log in with your mail account from play store and using whatever you want for your able business
          purpose.</p>
      </div>
    </div>
    <div class="col-xl-2 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg3">
          <i class="ri-leaf-line"></i>
        </div>
        <h3>Easy Editable Data</h3>
        <p>Just log in with your mail account from play store and using whatever you want for your able business
          purpose.</p>
      </div>
    </div>
    <div class="col-xl-2 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg4">
          <i class="ri-secure-payment-line"></i>
        </div>
        <h3>Fully Secured</h3>
        <p>Just log in with your mail account from play store and using whatever you want for your able business
          purpose.</p>
      </div>
    </div>
    <div class="col-xl-2 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg5">
          <i class="ri-cloud-line"></i>
        </div>
        <h3>Cloud Storage</h3>
        <p>Just log in with your mail account from play store and using whatever you want for your able business
          purpose.</p>
      </div>
    </div>
    <div class="col-xl-2 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg6">
          <i class="ri-pie-chart-2-line"></i>
        </div>
        <h3>Responsive Ready</h3>
        <p>Just log in with your mail account from play store and using whatever you want for your able business
          purpose.</p>
      </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
      <div class="view-more-box">
        <a routerLink="/features-1" class="default-btn">View More</a>
      </div>
    </div>
  </div>
</div>