<div class="stats-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">OUR STATS</span>
            <h2>Total User In This World</h2>
        </div>
        <div class="stats-map text-center">
            <img src="assets/img/map.png" alt="map">
        </div>
    </div>
</div>