<div class="banner-wrapper-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="banner-wrapper-content">
          <span class="sub-title">Secure Application</span>
          <h1>O melhor APP para seus cliente gerenciar veículos de qualquer lugar e a qualquer hora!</h1>
          <p>Oferece uma variedade de benefícios que vão desde a segurança e eficiência operacional até a redução de
            custos e aprimoramento da gestão de frotas.</p>
          <a routerLink="/contact" class="default-btn">Start Free Trial</a>
        </div>
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="banner-wrapper-image">
          <img src="assets/img/banner/banner-img1.png" alt="banner-img">
          <img src="assets/img/banner/banner-img2.png" data-aos="fade-left" alt="banner-img">
        </div>
      </div>
    </div>
  </div>
  <div class="shape13"><img src="assets/img/shape/shape15.png" alt="shape"></div>
  <div class="shape14"><img src="assets/img/shape/shape17.png" alt="shape"></div>
  <div class="shape15"><img src="assets/img/shape/shape18.png" alt="shape"></div>
</div>