<app-navbar-style-one></app-navbar-style-one>

<app-homenine-banner></app-homenine-banner>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-homenine-features></app-homenine-features>

<app-homenine-app-progress></app-homenine-app-progress>

<app-key-features></app-key-features>

<div class="app-screenshots-wrap-area ptb-100">
    <app-screenshots-style-two></app-screenshots-style-two>
</div>

<app-get-started></app-get-started>

<app-client-reviews-style-two></app-client-reviews-style-two>

<div class="pricing-area bg-gradient-color pt-100 pb-75">
    <app-pricing-style-one></app-pricing-style-one>
    <div class="shape7"><img src="assets/img/shape/shape6.png" alt="shape"></div>
    <div class="shape8"><img src="assets/img/shape/shape7.png" alt="shape"></div>
</div>

<app-homenine-blog></app-homenine-blog>

<app-free-trial-style-four></app-free-trial-style-four>

<app-footer-style-five></app-footer-style-five>