<div class="new-feedback-wrap-area pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <span class="sub-title">CLIENT REVIEWS</span>
            <h2>Some of Our Happy Customer Reviews About Our Products</h2>
        </div>
        <div class="new-feedback-wrap-slides">
            <owl-carousel-o [options]="clientReviewsSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-wrap-card">
                        <div class="rating">
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                        <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type."</p>
                        <div class="client-info">
                            <img src="assets/img/user/user1.jpg" alt="user">
                            <div class="title">
                                <h3>Deanna Hodges</h3>
                                <span>Spotify Developer</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-wrap-card">
                        <div class="rating">
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                        <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type."</p>
                        <div class="client-info">
                            <img src="assets/img/user/user2.jpg" alt="user">
                            <div class="title">
                                <h3>Deanna Hodges</h3>
                                <span>Spotify Developer</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-wrap-card">
                        <div class="rating">
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                        <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type."</p>
                        <div class="client-info">
                            <img src="assets/img/user/user3.jpg" alt="user">
                            <div class="title">
                                <h3>Deanna Hodges</h3>
                                <span>Spotify Developer</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>