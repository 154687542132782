<div class="products-area pb-75">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">PRODUCTS</span>
            <h2>Related Products</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details" class="main-image">
                            <img src="assets/img/products/products-img1.jpg" class="main-image" alt="image">
                        </a>
                        <a routerLink="/cart" class="default-btn">Add To Cart</a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Laptop Blue Background</a></h3>
                        <div class="price">
                            <span class="old-price">$321</span>
                            <span class="new-price">$250</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details" class="main-image">
                            <img src="assets/img/products/products-img2.jpg" class="main-image" alt="image">
                        </a>
                        <div class="sale-tag">Sale!</div>
                        <a routerLink="/cart" class="default-btn">Add To Cart</a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Pink Smartwatch</a></h3>
                        <div class="price">
                            <span class="old-price">$210</span>
                            <span class="new-price">$200</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details" class="main-image">
                            <img src="assets/img/products/products-img3.jpg" class="main-image" alt="image">
                        </a>
                        <a routerLink="/cart" class="default-btn">Add To Cart</a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Black Headphone</a></h3>
                        <div class="price">
                            <span class="old-price">$210</span>
                            <span class="new-price">$200</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>