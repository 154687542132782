<div class="new-software-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-software-list">
                    <img src="assets/img/more-home/software/border.png" alt="bg-shape">
                    <ul>
                        <li><img src="assets/img/software-integrations/atlassian.png" class="atlassian" alt="atlassian"></li>
                        <li><img src="assets/img/software-integrations/skype.png" class="skype" alt="skype"></li>
                        <li><img src="assets/img/software-integrations/gdrive.png" class="gdrive" alt="gdrive"></li>
                        <li><img src="assets/img/software-integrations/slack.png" class="slack" alt="slack"></li>
                        <li><img src="assets/img/software-integrations/jira.png" class="jira" alt="jira"></li>
                        <li><img src="assets/img/software-integrations/frame.png" class="frame" alt="frame"></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-software-content">
                    <div class="big-text">Software</div>
                    <span class="sub-title">SOFTWARE INTEGRATIONS</span>
                    <h2>Easy & Perfect Solution With Latest Software Integrations</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. never missyour chance its just began. backup just log in with your mail account from.</p>
                    <p>Most provabily best  for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy.</p>
                    <a routerLink="/contact" class="default-btn">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</div>