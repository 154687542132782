<app-navbar-style-one></app-navbar-style-one>

<app-homefour-banner></app-homefour-banner>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-homefour-video></app-homefour-video>

<app-homefour-features></app-homefour-features>

<div class="features-area pt-100">
    <app-features-style-three></app-features-style-three>
</div>

<app-progress-style-three></app-progress-style-three>

<div class="app-download-area pb-100">
    <app-download-app-style-three></app-download-app-style-three>
</div>

<app-screenshots-style-four></app-screenshots-style-four>

<app-homefour-software-integrations></app-homefour-software-integrations>

<div class="pricing-area pb-75">
    <app-pricing-style-four></app-pricing-style-four>
</div>

<app-feedback-style-three></app-feedback-style-three>

<div class="ptb-100">
    <app-free-trial-style-two></app-free-trial-style-two>
</div>

<app-footer-style-two></app-footer-style-two>