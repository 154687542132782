<div class="overview-area">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-xl-6 col-lg-12 col-md-12 p-0">
                <div class="overview-content">
                    <h2>Most Probably You Are Getting Best App Ever</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="ri-download-cloud-2-line"></i>
                            </div>
                            <h3>Free Download App</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your business purpose.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="ri-award-line"></i>
                            </div>
                            <h3>Trusted and Reliable</h3>
                            <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy.</p>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contact" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-1" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 p-0">
                <div class="overview-image bg1">
                    <img src="assets/img/overview1.jpg" alt="overview">
                </div>
            </div>
        </div>
    </div>
</div>