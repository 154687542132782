<div class="container">
    <div class="free-trial-inner">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="content">
                    <span class="sub-title">FREE TRIAL</span>
                    <h2>Start Your 14 Days Free Trials Today!</h2>
                    <form class="free-trial-form">
                        <input type="text" class="input-newsletter" placeholder="Enter Your Email Address" name="email">
                        <button type="submit" class="default-btn">Get Started</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="image">
                    <img src="assets/img/app/app-img6.png" alt="free-trial">
                </div>
            </div>
        </div>
        <div class="shape8"><img src="assets/img/shape/shape7.png" alt="shape"></div>
        <div class="shape9"><img src="assets/img/shape/shape8.png" alt="shape"></div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</div>