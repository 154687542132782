<div class="container">
    <div class="section-title">
        <span class="sub-title">APP SCREENS</span>
        <h2>Beautifully Crafted All App Screenshots</h2>
    </div>
    <div class="screenshots-slides">
        <owl-carousel-o [options]="screenshotsSlides">
            <ng-template carouselSlide>
                <div class="single-screenshot-item">
                    <img src="assets/img/screenshots/screenshots1.png" alt="screenshots">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-screenshot-item">
                    <img src="assets/img/screenshots/screenshots2.png" alt="screenshots">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-screenshot-item">
                    <img src="assets/img/screenshots/screenshots3.png" alt="screenshots">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-screenshot-item">
                    <img src="assets/img/screenshots/screenshots4.png" alt="screenshots">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-screenshot-item">
                    <img src="assets/img/screenshots/screenshots5.png" alt="screenshots">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-screenshot-item">
                    <img src="assets/img/screenshots/screenshots4.png" alt="screenshots">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>