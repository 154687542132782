<app-navbar-style-two></app-navbar-style-two>

<app-homethree-banner></app-homethree-banner>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-homethree-overview></app-homethree-overview>

<app-homethree-features></app-homethree-features>

<app-homethree-video></app-homethree-video>

<div class="features-area pt-100 pb-75">
    <app-features-style-two></app-features-style-two>
</div>

<app-screenshots-style-three></app-screenshots-style-three>

<app-software-integrations></app-software-integrations>

<div class="app-download-area pb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div>

<app-pricing-style-three></app-pricing-style-three>

<div class="feedback-area bg-F4F8FC ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</div>

<app-blog></app-blog>

<app-footer-style-one></app-footer-style-one>