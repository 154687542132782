<div class="feedback-area bg-F4F8FC ptb-100">
    <div class="container">
        <div class="feedback-tab-slides">
            <owl-carousel-o [options]="feedbackTabSlides">
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>Instant free download from store cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text.</p>
                        <h6><span>James Smith</span> - Web Developer</h6>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>Instant free download from store cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text.</p>
                        <h6><span>Sarah Taylor</span> - Web Designer</h6>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>Instant free download from store cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text.</p>
                        <h6><span>Andy John</span> - Developer</h6>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>Instant free download from store cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text.</p>
                        <h6><span>David Warner</span> - Web Developer</h6>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>