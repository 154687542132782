<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Feedback</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Feedback</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="feedback-area ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</div>

<div class="feedback-area bg-F4F8FC ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</div>

<div class="feedback-area ptb-100">
    <app-feedback-style-two></app-feedback-style-two>
</div>

<app-feedback-style-three></app-feedback-style-three>

<app-feedback-style-four></app-feedback-style-four>

<div class="testimonials-area ptb-100">
    <app-feedback-style-five></app-feedback-style-five>
</div>

<div class="partner-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>