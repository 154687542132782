<div class="gradient-funfacts-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-funfacts-card">
                    <div class="icon">
                        <i class="ri-download-2-line"></i>
                    </div>
                    <p>Total Downloads</p>
                    <h3><span class="odometer" [countUp]="10">00</span><span class="sign">M</span></h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-funfacts-card">
                    <div class="icon">
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>Total Reviews</p>
                    <h3><span class="odometer" [countUp]="799">00</span><span class="sign">K</span></h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-funfacts-card">
                    <div class="icon">
                        <i class="ri-global-line"></i>
                    </div>
                    <p>Worldwide Countries</p>
                    <h3><span class="odometer" [countUp]="150">00</span><span class="sign">+</span></h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-funfacts-card">
                    <div class="icon">
                        <i class="ri-map-pin-user-line"></i>
                    </div>
                    <p>Active Users</p>
                    <h3><span class="odometer" [countUp]="5">00</span><span class="sign">M</span></h3>
                </div>
            </div>
        </div>
    </div>
</div>