import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homefive-about',
  templateUrl: './homefive-about.component.html',
  styleUrls: ['./homefive-about.component.scss']
})
export class HomefiveAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
