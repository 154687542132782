<div class="gradient-banner-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="gradient-banner-content">
          <h1>O melhor aplicativo de rastreamento veicular para o seu cliente!</h1>
          <p>Tudo em um só lugar: tenha o controle da parte administrativa e
            operacional na palma da sua mão.</p>
          <!-- <form>
                        <input type="text" class="input-newsletter" placeholder="Enter Your Email Address" name="email">
                        <button type="submit" class="default-btn">Get Started</button>
                    </form> -->
          <a target="_blank"
            href="https://api.whatsapp.com/send/?phone=5585988646989&text&type=phone_number&text=Olá, estou interessado em uma demonstração do sistema&app_absent=0"
            class="default-btn">Solicite uma demonstração</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="gradient-banner-image" data-aos="fade-up">
          <img class="banner" src="assets/img/protrack/banners/cell.png" alt="banner-img">
        </div>
      </div>
    </div>
  </div>
  <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
  <div class="banner-shape2"><img src="assets/img/shape/shape7.png" alt="image"></div>
</div>


<div class="partner-area ptb-100">
  <div class="container">
    <div class="partner-title">
      Equipamentos homologados:
    </div>
    <app-partner></app-partner>
  </div>
</div>

<app-homefour-features></app-homefour-features>