<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Team</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="team-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-1"></div>
                    </div>
                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>Marketing Lead</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-2"></div>
                    </div>
                    <div class="content">
                        <h3>Ben Stokes</h3>
                        <span>Art Director</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-3"></div>
                    </div>
                    <div class="content">
                        <h3>Ellyse Perry</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-4"></div>
                    </div>
                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>Development</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-5"></div>
                    </div>
                    <div class="content">
                        <h3>Suzie Bates</h3>
                        <span>Front-End Development</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-6"></div>
                    </div>
                    <div class="content">
                        <h3>David Warner</h3>
                        <span>Head of Team</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-7"></div>
                    </div>
                    <div class="content">
                        <h3>Ross Taylor</h3>
                        <span>Front-End Developer</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-8"></div>
                    </div>
                    <div class="content">
                        <h3>Meg Lanning</h3>
                        <span>Art Director</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-9"></div>
                    </div>
                    <div class="content">
                        <h3>Du Plessis</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-10"></div>
                    </div>
                    <div class="content">
                        <h3>Ellyse Perry</h3>
                        <span>Development</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-11"></div>
                    </div>
                    <div class="content">
                        <h3>Kagiso Steyn</h3>
                        <span>Front-End Development</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="partner-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>