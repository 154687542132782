<div class="new-app-main-banner-gradient-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-gradient-content">
                    <span class="sub-title">#Get Your 14 Days Free Trial</span>
                    <h1>The Revolutionary App That Make Your Life Easier Than Others</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id tincidunt eifend odio viverra diam aliquet donec again.</p>
                    <div class="banner-btn">
                        <a routerLink="/app-download" class="default-btn">Start Free Trial</a>
                    </div>
                </div>
            </div> 
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-gradient-image">
                    <img src="assets/img/more-home/banner/banner-3.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>