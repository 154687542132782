<div class="new-app-progress-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="new-app-progress-content">
          <div class="big-text">Progress</div>
          <span class="sub-title">Plataforma em constante evolução</span>
          <h2>Set Up The Challenges and Track Your Progress</h2>
          <p>Cloud based storage for your data backup just log in with your mail account from play store and using
            whatever you want for your business purpose orem ipsum dummy text. never missyour chance its just began.
            backup just log in with your mail account from.</p>
          <p>Most provabily best for your data backup just log in with your mail account from play store and using
            whatever you want for your business purpose orem ipsum dummy backup just log in with your mail account from.
          </p>
          <a routerLink="/app-download" class="default-btn">Start Free Trial</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="new-app-progress-image text-center">
          <img src="assets/img/more-home/progress/progress.png" alt="app-img">
        </div>
      </div>
    </div>
  </div>
  <div class="new-app-progress-shape">
    <img src="assets/img/more-home/progress/shape-1.png" alt="image">
  </div>
</div>