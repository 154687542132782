import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homefour-video',
    templateUrl: './homefour-video.component.html',
    styleUrls: ['./homefour-video.component.scss']
})
export class HomefourVideoComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

    // Video Popup
    isOpen = false;
    openPopup(): void {
        this.isOpen = true;
    }
    closePopup(): void {
        this.isOpen = false;
    }

}