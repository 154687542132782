import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeone-features',
  templateUrl: './homeone-features.component.html',
  styleUrls: ['./homeone-features.component.scss']
})
export class HomeoneFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
