import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homefive-features',
  templateUrl: './homefive-features.component.html',
  styleUrls: ['./homefive-features.component.scss']
})
export class HomefiveFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
