<div class="main-banner">
    <div class="container">
        <div class="content">
            <span class="sub-title">Get your 14 days free trial</span>
            <h1>The Revolutionary App That Makes Your Life Easier Than Others</h1>
            <a routerLink="/sign-in" class="default-btn">Start Free Trial</a>
            <img src="assets/img/banner/banner-app3.png" alt="image">
        </div>
    </div>
    <div class="divider"></div>
    <div class="banner-shape3"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="banner-shape8"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="banner-shape7"><img src="assets/img/shape/shape11.png" alt="image"></div>
</div>