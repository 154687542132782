<div class="contact-info-area pb-100">
  <div class="container">
    <div class="contact-info-inner">
      <h2>Quer falar conosco? Entre em contato por E-mail ou WhatsApp</h2>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-contact-info-box">
            <div class="icon bg1">
              <i class="ri-whatsapp-line"></i>
            </div>
            <h3>Suporte:</h3>
            <h3><a target="_blank"
                href="https://api.whatsapp.com/send/?phone=5585988646989&text&type=phone_number&text=Olá!&app_absent=0">+55
                (85) 98864-6989 </a></h3>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-contact-info-box">
            <div class="icon">
              <i class="ri-earth-line"></i>
            </div>
            <h3>Email:</h3>
            <h3><a href="mailto:contato@protrack.com.br">contato@protrack.com.br</a></h3>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-contact-info-box">
            <div class="icon bg2">
              <i class="ri-map-pin-line"></i>
            </div>
            <h3><a style="font-size: 16px;" target="_blank"
                href="https://www.google.com/maps?ll=-3.737292,-38.499139&z=16&t=m&hl=pt-BR&gl=BR&mapclient=embed&cid=5894094269058598696">
                BS Design Torre norte. Av. Desmb. Moreira, 1300. Aldeota, Fortaleza - CE</a></h3>
          </div>
        </div>
      </div>
      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</div>