<div class="features-area pt-100 pb-75">
    <div class="container">
        <ul class="features-boxes-list">
            <li>
                <div class="features-list-box">
                    <div class="icon">
                        <i class="ri-smartphone-line"></i>
                    </div>
                    <h3>User Friendly</h3>
                </div>
            </li>
            <li>
                <div class="features-list-box">
                    <div class="icon bg2">
                        <i class="ri-award-line"></i>
                    </div>
                    <h3>Award Winning App</h3>
                </div>
            </li>
            <li>
                <div class="features-list-box">
                    <div class="icon bg3">
                        <i class="ri-fingerprint-line"></i>
                    </div>
                    <h3>Privacy Protected</h3>
                </div>
            </li>
            <li>
                <div class="features-list-box">
                    <div class="icon">
                        <i class="ri-vip-diamond-fill"></i>
                    </div>
                    <h3>Lifetime Update</h3>
                </div>
            </li>
            <li>
                <div class="features-list-box">
                    <div class="icon bg4">
                        <i class="ri-customer-service-2-line"></i>
                    </div>
                    <h3>24/7 Support</h3>
                </div>
            </li>
        </ul>
    </div>
</div>