import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeeight-features',
  templateUrl: './homeeight-features.component.html',
  styleUrls: ['./homeeight-features.component.scss']
})
export class HomeeightFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
